import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Sections from '../../components/Sections'
import { SideQuote } from '../../components/Elements'
import { Link } from 'gatsby'
import Img from 'gatsby-image'


const P = ({ children, className, ...restProps }) => <p {...restProps} className={`mb-4 leading-loose ${className}`}>{children}</p>

const Seedrs = props => {

    console.log()
    const bg = props.data.bg.childImageSharp;
    const newAcademy = props.data.newAcademy.childImageSharp.fluid;
    const oldAcademy = props.data.oldAcademy.childImageSharp.fluid;
    const weeklyContent = props.data.weeklyContent.childImageSharp.fluid;
    const seoVisibility = props.data.seoVisibility.childImageSharp.fluid;
    const pageViews = props.data.pageViews.childImageSharp.fluid;
    

    return (
        <>
            <SEO
                title={"Seedrs Content Strategy Case Study"}
                description={"A review of the strategy and results of the content strategy I implemented for Seedrs during my time working there."}
                image={bg.fixed.src} 
            />
            <Layout
                homeLink={{ linkTo: "case-studies/seedrs#top", name: "Home" }}
                navLinks={[
                    { linkTo: "case-studies/seedrs#about", name: "About" },
                    { linkTo: "case-studies/seedrs#strategy", name: "Strategy" },
                    { linkTo: "case-studies/seedrs#results", name: "Results" },
                    { linkTo: "case-studies/seedrs#seo", name: "SEO" },
                ]}
            >

                <Sections.Header id="top" header="Case Study" subHeader="Seedrs Content Strategy Case Study" img={bg.fluid} />

                < Sections.Section>
                    <h2 id="about" className="mb-4">About Seedrs</h2>
                    <P className="mb-4 leading-loose">Seedrs is an equity crowdfunding platform that enables all types of investors to invest in companies they believe in and share in their success from as little as £10. In the same step, the platform supports all types of ambitious growth businesses to raise capital and build a community of followers.
                    </P>
                    <h3 id="strategy" className="mb-4">The Strategy</h3>
                    <P>
                        For a year prior to going fully freelance, I worked at Seedrs as a Content Marketing Manager to establish their content team and deliver quality content that engages, delivers value and drives action for both investors and entrepreneurs alike. Before I joined, the position had been open for over six months and they desperately needed someone to take control of their content and drive it above and beyond what they were currently producing. During my interviews, I pitched my content strategy vision which focused on producing content that addressed the three stages of entrepreneurial life (as they have stated their initial goal was to attract more startups and entrepreneurs to increase their deal flow - later on, I started producing more investor-related content).
                    </P>
                    <P>
                        The three stages are as follows - the <i>wantrepreneurs</i>, the people that are toying with the idea of starting a business or just getting started, this is the largest base, but the least likely to have an immediate effect on Seedrs’ bottom line. The second stage is the seed/post-seed stage or <i>growth entrepreneurs</i>, who are seeking content to help them take their startup to the next level. These were companies they wanted to attract and then nurture through further rounds of funding. Finally, the third stage was those <i>established entrepreneurs</i> (and companies), the big-ticket catches, the ones raising multi-million-pound rounds for their series B, C, D etc. These were harder to create content for as the people running and making the decision about where to raise capital from were undoubtedly busy and had large levels of experience in the startup world already. Whilst we could create content for them (and we did) this content would also serve as content for those in the second stage looking forward to their company's future.
                    </P>
                    <P>
                        Content marketing is a long-term strategy that takes many months, depending on the business to reap the rewards. But the time and effort invested into creating stellar, ever-green content cannot be underestimated as it creates value that far outweighs the cost to produce.
                    </P>
                    <P>
                        It was on this basis that I pitched them the idea of Seedrs Academy, a content hub that would help them on their journey through articles and advice. This focused on a content strategy that built trust and awareness across the sales funnel, positioned Seedrs as experts, and nurtured leads until they were ready to make a purchasing decision. The overarching goal of the strategy was to become the number one destination to help startup founders to not just raise funds, but manage their startup better.
                    </P>
                    <h3 id="results" className="mb-4">Results</h3>
                    <P>
                        Having produced over 60 pieces of content during the development of the site and months of working alongside designers and developers, we launched the Seedrs Academy in January 2020.
                    </P>
                    <P>
                        Delivery of Seedrs Academy content hub.
                        Purpose:
                    </P>
                    <ul className="list-inside pl-4">
                        <li className="list-disc">Create a beautiful home for the marketing team's high-quality content, building value into the user experience and the Seedrs brand.</li>
                        <li className="list-disc">Broaden value proposition for entrepreneurs - Seedrs don’t just assist in raising funds, but helps you with every facet of growing a business.</li>
                        <li className="list-disc">Helps scale the entrepreneur acquisition by providing a high performing lead generation engine.</li>
                    </ul>

                    <div className="flex lg:flex-row flex-col my-8">

                        <div className="w-full lg:w-1/2 mb-6 lg:mb-0 ">
                            <Img className="w-full" fluid={oldAcademy} alt="The Old Academy Page"/>
                            <h4 className="self-end w-full text-center mt-4 ">Before</h4>
                        </div>
                        <div className="w-full lg:w-1/2 relative">
                            <Img className="w-full " fluid={newAcademy} alt="The New Academy Page"/>
                            <h4 className="lg:absolute bottom-0 text-center block w-full mt-4">After</h4>
                        </div>
                    </div>

                    <P className="py-12">After publishing the Academy, we saw week on week records of page views. Total entrepreneur page views doubled in number in 9 months.</P>
                    <Img className="my-12 " imgStyle={{ objectFit: "contain" }} fluid={pageViews} alt="Page view graph"/>
                    <P> Organic traffic to entrepreneur content grew by <b>10x in 9 months</b>.</P>
                    <Img className="w-full p-12 my-12 " imgStyle={{ objectFit: "contain" }} fluid={weeklyContent} alt="Weekly content traffic graph"/>
                    <P id="seo">We overtook our closest competitor in SEO visibility.</P>
                    <Img className="w-full p-12 mb-12 " fluid={seoVisibility} alt="The New Academy Page"/>

                    <P>We became a <b>Top Writer on Medium</b> in 'Startup' and 'Entrepreneurship' and 'Leadership' with pieces regularly distributed by curators.</P>

                    {/* Image */}
                    <P>Pages Per Session increased by 62%. </P>


                    <P>Most importantly, feedback from users demonstrated the value we were providing: </P>

                    <SideQuote author=" " className="leading-10 border-accent ml-12 my-6">
                        “Hi Seedrs,
                         <br/>
                        I'm really confused because you're giving me the best kind of advice that's new, relevant and hard to find.
                        <br/>
                        This treasure trove of information you've linked me is worth multiple mentors and gives me more than paid courses. What's the catch?
                        <br/>
                        Really appreciate you guys and I'm glad I signed up. 
                        <br />
                        Sharing your wisdom with my team.”
                    </SideQuote>
                    <P>As you can see from the results, strategic content marketing can have a remarkable positive impact on a business, improving brand awareness through SEO and building authority in an industry. </P>

                    <P>Content, when properly executed and promoted, can yield powerful results with compounding returns. </P>

                    <P>
                        If you are interested in taking your business further and are ready to use content marketing to take your brand to new heights, reach out to ellie@elliecollins.me or go through the <Link className="text-accent font-bold" to="/#contact-me">contact</Link> section of my website.
                    </P>


                </Sections.Section>
            </Layout>
        </>
    )
}


export const SeedrsQuery = graphql`
query SeedrsQuery {
    bg: file(relativePath: { eq: "case-studies/seedrs/case-study.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1600) {
                    ...GatsbyImageSharpFluid
                    }
                fixed(width: 200) {
                    src
                }     
            }
    }
    newAcademy: file(relativePath: { eq: "case-studies/seedrs/new-academy.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                    }
            }
    }
    oldAcademy: file(relativePath: { eq: "case-studies/seedrs/old-academy.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                    }
            }
    }
    weeklyContent: file(relativePath: { eq: "case-studies/seedrs/weekly-content.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                    }
            }
    }
    seoVisibility: file(relativePath: { eq: "case-studies/seedrs/seo-visibility.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                    }
            }
    }
    pageViews: file(relativePath: { eq: "case-studies/seedrs/page-views.png" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                    }
            }
    }
}
  
`
Seedrs.propTypes = {

}

export default Seedrs
